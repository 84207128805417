<template>
  <div>
    <v-overlay>
      <v-card light width="30rem">
        <v-card-title> Login </v-card-title>
        <v-card-text>
          <v-text-field
            dense
            label="E-Mail"
            v-model="email"
            filled
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            dense
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            v-model="password"
            filled
            :append-outer-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-outer="showPassword = !showPassword"
            @keypress.enter="$emit('login', { email, password })"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="resetPasswordDialog = true">Reset Password</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="$emit('login', { email, password })">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>

    <v-dialog v-model="resetPasswordDialog">
      <reset-password
        :original-email="email"
        @close="resetPasswordDialog = false"
      ></reset-password>
    </v-dialog>
  </div>
</template>

<script>
import ResetPassword from "./ResetPasswordDialog.vue";
export default {
  components: { ResetPassword },
  data: () => ({
    email: "",
    password: "",
    showPassword: false,
    resetPasswordDialog: false
  })
};
</script>
