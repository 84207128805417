<template>
  <v-app>
    <v-main>
      <login v-if="component == 'login'" @login="login"></login>
      <home v-if="component == 'home'" @logout="logout"></home>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import wretch from "wretch";
import Login from "./components/Login.vue";
import Home from "./views/Home.vue";
wretch;

export default {
  components: { Login, Home },
  name: "App",

  data: () => ({
    token: "",
    component: "",
    super: false
  }),

  methods: {
    login({ email, password }) {
      wretch("/api/login")
        .formData({ email, password })
        .post()
        .json()
        .then(({ status, data }) => {
          if (status != "ok") {
            console.error("Invalid Login.");
            return;
          } else {
            data = JSON.parse(data);
            this.token = data.token;
            localStorage.setItem("agps-private-vids-token", data.token);
            this.super = data.super;
            this.component = "home";
          }
        });
    },
    logout() {
      localStorage.removeItem("agps-private-vids-token");
      this.component = "login";
    }
  },

  mounted() {
    if (this.$route.path.includes("reset-password")) return;

    this.token = localStorage.getItem("agps-private-vids-token");
    if (!this.token || this.token == "") {
      this.component = "login";
    } else {
      wretch("/api/validate-token")
        .auth(`Bearer ${this.token}`)
        .get()
        .json()
        .then(({ status, data }) => {
          if (status == "ok") {
            data = JSON.parse(data);
            this.super = data.super;
          }
          this.component = status == "ok" ? "home" : "login";
        });
    }
  }
};
</script>
