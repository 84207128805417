<template>
  <div v-if="valid">
    <v-overlay>
      <v-card light width="75vw">
        <v-card-title> Reset Password </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="password"
            label="New Password"
            :type="showPassword ? 'text' : 'password'"
            filled
            :append-outer-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-outer="showPassword = !showPassword"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="cancel">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="resetPassword">Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
import wretch from "wretch";
wretch;

export default {
  data: () => ({
    resetToken: "",
    valid: false,
    showPassword: false,
    password: ""
  }),
  methods: {
    cancel() {
      location = "/";
    },
    resetPassword() {
      wretch("/api/reset-password")
        .auth(`Reset ${this.resetToken}`)
        .formData({ password: this.password })
        .post()
        .json()
        .then(({ result }) => {
          const msg = result
            ? "Password reset. Check email for confirmation."
            : "Unable to update password.";

          alert(msg);

          location = "/";
        });
    }
  },
  mounted() {
    this.resetToken = this.$route.params.resetToken;
    wretch("/api/verify-reset-token")
      .auth(`VerifyReset ${this.resetToken}`)
      .get()
      .json()
      .then(({ validToken }) => {
        if (!validToken) {
          alert("Reset token is invalid or expired.");
          location = "/";
          return;
        }

        this.valid = true;
      });
  }
};
</script>
