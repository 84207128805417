<template>
  <div>
    <v-toolbar dense dark flat>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('logout')">Logout</v-btn>
    </v-toolbar>
    <v-container>
      <v-data-iterator
        :items="vidList"
        :items-per-page="$vuetify.breakpoint.mdAndUp ? 10 : 6"
        hide-default-footer
        :search="search"
        sort-by="title"
      >
        <template #header="{ pagination, options }">
          <data-iterator-header
            :pagination="pagination"
            @prev-page="options.page--"
            @next-page="options.page++"
            @update-search="updateSearch"
          ></data-iterator-header>
        </template>

        <template #default="{ items }">
          <v-row>
            <v-col
              v-for="vid in items"
              :key="`vid-list-item-${vid.id}`"
              cols="6"
            >
              <vid-card :vid="vid"></vid-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import wretch from "wretch";
import DataIteratorHeader from "../components/DataIteratorHeader.vue";
import VidCard from "../components/VidCard.vue";
wretch;

export default {
  components: { DataIteratorHeader, VidCard },
  data: () => ({
    vidList: [],
    search: ""
  }),
  methods: {
    updateSearch(v) {
      this.search = v;
    }
  },
  mounted() {
    if (this.vidList.length == 0) {
      wretch("/api/get-vid-list")
        .auth(`Bearer ${localStorage.getItem("agps-private-vids-token")}`)
        .get()
        .json()
        .then(({ status, data }) => {
          if (status == "ok") {
            this.vidList = JSON.parse(data);
          } else {
            console.error("Invalid user.");
          }
        });
    }
  }
};
</script>
