<template>
  <div>
    <v-toolbar flat>
      <v-text-field
        label="Search"
        v-model="searchValue"
        clear-icon="mdi-close"
        clearable
        @keydown.esc="searchValue = ''"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon :disabled="pagination.page == 1" @click="$emit('prev-page')">
        <v-icon> mdi-chevron-left </v-icon>
      </v-btn>
      {{ pagination.page }} of {{ pagination.pageCount }}
      <v-btn
        icon
        :disabled="pagination.page == pagination.pageStop"
        @click="$emit('next-page')"
      >
        <v-icon> mdi-chevron-right </v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  props: ["pagination", "search"],
  computed: {
    searchValue: {
      get() {
        return this.search;
      },
      set(v) {
        this.$emit("update-search", v);
      }
    }
  }
};
</script>
