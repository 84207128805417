<template>
  <div>
    <v-card>
      <v-card-title> Reset Password </v-card-title>
      <v-card-text>
        <v-text-field
          label="E-Mail"
          v-model="email"
          filled
          hint="Must be the e-mail address you use to login to this page."
        ></v-text-field>
      </v-card-text>
      <v-card-text>
        If you click <em>CONTINUE</em>:
        <ul>
          <li>you will receive an email with a link to reset your password</li>
          <li>the link will only work once, and it will expire within a day</li>
          <li>
            follow the instructions at the linked page to reset your password
          </li>
          <li>you will receive a confirmation email after the reset happens</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="$emit('close')">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="reset">Continue</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import wretch from "wretch";
wretch;

export default {
  props: ["originalEmail"],
  data() {
    return {
      email: this.originalEmail
    };
  },
  methods: {
    reset() {
      wretch("/api/send-reset-password")
        .formData({ email: this.email })
        .post()
        .json()
        .then(() => {
          location = "/";
        });
    }
  }
};
</script>
