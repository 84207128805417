<template>
  <div>
    <v-card>
      <v-list-item :href="vid.url" target="_blank">
        <v-list-item-content>
          <v-list-item-title>
            {{ title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ subtitle }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <!-- <v-card :href="vid.url" target="_blank">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-subtitle>
        {{ subtitle }}
      </v-card-subtitle>
    </v-card> -->
  </div>
</template>

<script>
export default {
  props: ["vid"],
  computed: {
    title() {
      return this.vid.title.split("-").shift();
    },
    subtitle() {
      return this.vid.title.split("-").slice(1).join("-");
    }
  }
};
</script>
